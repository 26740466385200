import $ from 'jquery-slim';

class Disable
{
    constructor($element)
    {
        this.$element = $element;

        this.$disable = $(this.$element.attr('data-disable-target'));


        let id = this.$element.attr('for');
        this.$checkbox = $('#' + id);
        this.$checkbox.change(this.change.bind(this));
    }

    change()
    {
        this.$disable.attr('disabled', this.$checkbox.is(':checked') ? '' : null);
    }
}

class OptCheckedDisable
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $elements = $('[data-disable-target]');

        this.disables = [];

        for (let i = 0; i < $elements.length; i++) this.disables.push(new Disable($elements.eq(i)));
    }

}

const chekedDisable = new OptCheckedDisable();






